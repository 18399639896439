<template>
  <div class="content">
    <div class="page_title">
      <img src="../../assets/images/detail/left_back.png" alt @click="goBack">
      <span>修改密码</span>
    </div>
    <div>
      <div>
        <p :class="['item',{'login-focus':oldPsd}]">请输入原密码</p>
        <input v-model.trim="oldPsd" type="password" maxlength="8" @keyup="passWordValue">
      </div>
      <div>
        <p
          :class="['item',{'login-focus':newPad},{error:error}]"
        >请输入新密码（8-15位，至少包含大小写字母、数字和特殊字符）</p>
        <input
          v-model.trim="newPad"
          type="password"
          maxlength="15"
          @keyup="passWordValue"
          @blur="newPadBlur"
        >
      </div>
      <div>
        <p
          :class="['item',{'login-focus':againNewPsd},{error:error1}]"
        >{{ error1 ? errorMsg : '请确认新密码' }}</p>
        <input
          v-model.trim="againNewPsd"
          type="password"
          maxlength="15"
          @keyup="passWordValue"
          @blur="againNewPsdBlur"
        >
      </div>
      <div
        v-if="oldPsd == '' || newPad == '' || againNewPsd == '' || !validatePassword(newPad) || newPad !== againNewPsd || !unbind"
        class="comfirmBtn"
      >确认修改</div>
      <div v-else class="comfirmBtn blue" @click="resetPassword()">确认修改</div>
    </div>
  </div>
</template>

<script>
import port from '@/api/port';
import cookies from '@/utils/cookie.js';

export default {
  name: 'ChangeWord',
  data() {
    return {
      oldPsd: '',
      newPad: '',
      againNewPsd: '',
      error: false,
      error1: false,
      errorMsg: '',
      unbind: true
    };
  },
  created() {},
  methods: {
    register() {
      this.$router.push({
        path: 'register'
      });
    },
    agreementUser() {
      this.$router.push({
        path: 'agreementUser'
      });
    },
    // 密码输入字符限制
    passWordValue() {
      this.oldPsd = this.oldPsd.replace(/[^a-zA-Z0-9]/g, '');
      this.newPad = this.newPad.replace(/[^a-zA-Z0-9@#$%^&*(),.?":{}|<>]/g, '');
      this.againNewPsd = this.againNewPsd.replace(/[^a-zA-Z0-9@#$%^&*(),.?":{}|<>]/g, '');
    },
    // 校验新密码规则
    newPadBlur() {
      const Vtdb = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
      const newValue = this.newPad;

      if (!Vtdb.test(newValue) && newValue.length > 0) {
        this.errorMsg = '密码格式不正确，请输入8-15位包含大小写字母、数字和特殊字符的密码';
        this.error = true;
      } else if (newValue.length === 0) {
        this.errorMsg = '请输入新密码';
        this.error = true;
      } else {
        this.error = false;
      }
    },
    // 校验两次密码输入是否一致
    againNewPsdBlur() {
      if (this.againNewPsd.length === 0) {
        this.errorMsg = '请再次输入新密码';
        this.error1 = true;
      } else if (this.newPad !== this.againNewPsd) {
        this.errorMsg = '两次密码输入不一致';
        this.error1 = true;
      } else {
        this.error1 = false;
      }
    },
    // 验证密码规则
    validatePassword(password) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
      return regex.test(password);
    },
    resetPassword() {
      const _this = this;

      if (!this.validatePassword(this.newPad)) {
        this.$toast({ message: '新密码格式不正确，请输入8-15位包含大小写字母、数字和特殊字符的密码' });
        this.newPad = '';
        this.againNewPsd = '';
        return;
      }

      if (this.newPad === this.oldPsd) {
        this.$toast({ message: '新密码与原密码一致' });
        this.newPad = '';
        this.againNewPsd = '';
        return;
      }

      if (this.newPad !== this.againNewPsd) {
        this.$toast({ message: '两次密码输入不一致' });
        this.newPad = '';
        this.againNewPsd = '';
        return;
      }

      const data = {
        method: '100003',
        token: cookies.get('token'),
        password: this.oldPsd,
        new_password: this.newPad,
        two_password: this.againNewPsd
      };

      this.$Indicator.open();
      _this.unbind = false;

      port.portAddress(data).then(res => {
        this.$Indicator.close();

        if (res.status === 200) {
          if (res.msg === 100203) {
            _this.unbind = true;
            this.$toast({ message: res.data });
          } else {
            this.$toast({ message: '修改成功，请重新登录' });

            setTimeout(() => {
              cookies.remove('token');
              _this.$router.push({
                path: '/login'
              });
            }, 3000);
          }
        } else {
          _this.unbind = true;
          this.$toast({ message: res.msg });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.content {
  height: 100vh;
  & > :nth-child(2) {
    margin-top: 0.84rem;
    height: 3.37rem;
    background-color: #fff;
    & > div {
      height: 1.12rem;
      position: relative;
      img {
        position: absolute;
        width: 0.27rem;
        height: 0.27rem;
        top: 0.46rem;
        transition: 0.7s;
      }
      input {
        display: block;
        width: 100%;
        padding-bottom: 0.2rem;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 0.01rem solid #ededed;
        font-size: 0.24rem;
        color: #333;
        text-indent: 0.27rem;
        position: absolute;
        top: 0.6rem;
        z-index: 1;
        border-radius: 0;
      }
      p.item {
        position: absolute;
        font-size: 0.24rem;
        color: #999;
        padding: 0 0.27rem;
        top: 0.6rem;
        transition: 0.7s;
        z-index: 0;
      }
      .login-focus {
        top: 0.15rem !important;
        font-size: 0.2rem !important;
      }
    }
    .comfirmBtn {
      width: 5.38rem;
      height: 0.68rem;
      line-height: 0.68rem;
      background: rgba(47, 138, 241, 0.2);
      border-radius: 0.1rem;
      text-align: center;
      font-size: 0.27rem;
      color: #fff;
      margin: 0 auto;
      margin-top: 0.2rem;
    }
    .blue {
      background: #2f8af1;
    }
  }
}
</style>
<style>
.mint-toast.is-placemiddle {
  top: 30%;
}
</style>
